/* eslint-disable jsx-a11y/alt-text */
import React, { useState, createRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ArrowRight } from 'phosphor-react';
import axios from 'axios';
import {
    isPossiblePhoneNumber,
    // @ts-ignore
} from 'react-phone-number-input';
import { useToasts } from 'react-toast-notifications';
import ReCAPTCHA from 'react-google-recaptcha';

import LandingPage from './pages/LandingPage';
import FetchInfoPage from './pages/FetchInfoPage';
import FormPage1 from './pages/FormPage1';
import FormPage2 from './pages/FormPage2';
import ReviewPage from './pages/ReviewPage';
import ThankYouPage from './pages/ThankYouPage';
import { VisitorDetailsProps } from './pages/types';
import Header from './components/Header';
import { apiURL, recaptchaSiteKey } from './config';

import './App.scss';
import watermark from './assets/images/logo-watermark-min.png';
import { useEffect } from 'react';

const App = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [visitorDetails, setVisitorDetails] = useState({
        name: '',
        designation: '',
        company_name: '',
        email: '',
        phone_number: '',
        meeting_details: '',
        captcha: '',
    } as VisitorDetailsProps);
    const [fetchInput, setFetchInput] = useState<string>('');
    const [country, setCountry] = useState<string>('AE');
    const [processing, setProcessing] = useState(false);
    const { addToast, removeAllToasts } = useToasts();
    const recaptchaRef = createRef<ReCAPTCHA>();

    useEffect(() => {
        document.getElementById('watermark')!.style.left = `${
            -190 + (currentPage - 1) * -40
        }vw`;
    }, [currentPage]);

    const fetchDetails = () => {
        const form = document.getElementById('form') as HTMLFormElement;
        if (formValidity(form)) {
            setProcessing(true);
            axios
                .get(apiURL + `/visitordetails/${fetchInput}`)
                .then((res) => {
                    if (res.data[0]) {
                        const details: VisitorDetailsProps & {
                            datetime: string;
                        } = res.data[0];
                        setVisitorDetails({
                            ...visitorDetails,
                            name: details.name,
                            designation: details.designation,
                            company_name: details.company_name,
                            email: details.email,
                            phone_number: details.phone_number,
                        });
                    } else {
                        setVisitorDetails({
                            name: '',
                            designation: '',
                            company_name: '',
                            email: '',
                            phone_number: fetchInput,
                            meeting_details: '',
                            captcha: '',
                        });
                    }
                })
                .finally(() => {
                    setCurrentPage(currentPage + 1);
                    setProcessing(false);
                });
        }
    };

    const notEmptyText = () => {
        const t_inp: NodeListOf<HTMLInputElement> =
            document.querySelectorAll('input[type=text]');

        for (let inp of t_inp) {
            if (!inp.value.trim()) {
                inp.setCustomValidity('Please enter a value');
                inp.reportValidity();
                break;
            }
        }
    };

    const validPhoneNumber = () => {
        if (currentPage !== 2) {
            return;
        }
        let phoneNumberInput = document.querySelectorAll(
            'input[type=tel]'
        )[0] as HTMLInputElement;
        if (isPossiblePhoneNumber(phoneNumberInput.value)) {
            phoneNumberInput.setCustomValidity('');
            return;
        }
        phoneNumberInput.setCustomValidity('Please enter a valid phone number');
    };

    const formValidity = (form: HTMLFormElement): boolean => {
        notEmptyText();
        validPhoneNumber();
        if (form && [2, 3, 4].includes(currentPage)) {
            if (form.checkValidity()) {
                return true;
            } else {
                form.reportValidity();
                return false;
            }
        } else {
            return true;
        }
    };

    const changePage = (page: number) => {
        const nextBtn = document.getElementsByClassName(
            'nextBtn'
        )[0] as HTMLElement;
        const form = document.getElementById('form') as HTMLFormElement;
        if (nextBtn) {
            nextBtn.style.bottom = '2rem';
        }
        if (page < currentPage || formValidity(form)) {
            if (page < currentPage) {
                recaptchaRef.current?.reset();
                setVisitorDetails({ ...visitorDetails, captcha: '' });
            }

            // if (currentPage === 2) {
            //     fetchDetails();
            // }

            if (page >= 6) {
                setCurrentPage(6);
            } else if (page <= 1) {
                setCurrentPage(1);
            } else {
                setCurrentPage(page);
            }

            removeAllToasts();
        }
        if ([2, 3, 4, 5].includes(page)) {
            document.body.style.overflowY = 'auto';
        } else {
            document.body.style.overflowY = 'hidden';
        }
    };

    const formSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (processing || currentPage !== 5) {
            return;
        }
        console.log(visitorDetails);
        setProcessing(true);
        const formData = {};
        delete Object.assign(formData, visitorDetails).captcha;
        axios
            .post(apiURL + '/initiateworkflow', formData)
            .then(({ status }: { status: number }) => {
                if (status === 201) {
                    setVisitorDetails({
                        name: '',
                        designation: '',
                        company_name: '',
                        email: '',
                        phone_number: '',
                        meeting_details: '',
                        captcha: '',
                    });
                    setFetchInput('');
                    setProcessing(false);
                    changePage(currentPage + 1);
                }
            })
            .catch((error) => {
                setProcessing(false);
                addToast('Sorry, something went wrong. Please try again.', {
                    appearance: 'warning',
                    autoDismiss: true,
                    autoDismissTimeout: 7000,
                });
            });
    };

    const changeForm: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.target.setCustomValidity('');
        setVisitorDetails({ ...visitorDetails, [e.target.id]: e.target.value });
    };

    const setPhoneNumber = (number: string) => {
        setVisitorDetails({ ...visitorDetails, phone_number: number });
    };

    const setCaptchaValue = (value: string | null) => {
        setVisitorDetails({ ...visitorDetails, captcha: value });
    };

    return (
        <>
            <div className='container h-100'>
                <LandingPage
                    currentPage={currentPage}
                    changePage={changePage}
                />
                {[2, 3, 4, 5].includes(currentPage) && (
                    <>
                        <div className='d-flex justify-content-center'>
                            <Header
                                currentPage={currentPage}
                                changePage={changePage}
                            />
                        </div>
                        <div
                            className='container text-left px-0 py-3 pb-md-5 pt-md-3'
                            id='visitor-form'
                        >
                            <Form
                                onSubmit={formSubmit}
                                id='form'
                                className='h-100 d-flex flex-grow-1 flex-column'
                            >
                                <FetchInfoPage
                                    currentPage={currentPage}
                                    fetchInput={fetchInput}
                                    country={country}
                                    setCountry={setCountry}
                                    setFetchInput={setFetchInput}
                                />
                                <FormPage1
                                    currentPage={currentPage}
                                    changeForm={changeForm}
                                    visitorDetails={visitorDetails}
                                />
                                <FormPage2
                                    currentPage={currentPage}
                                    changeForm={changeForm}
                                    visitorDetails={visitorDetails}
                                    setPhoneNumber={setPhoneNumber}
                                    setCaptchaValue={setCaptchaValue}
                                    recaptchaRef={recaptchaRef}
                                />
                                <div
                                    id='recaptcha'
                                    style={{
                                        marginBottom: '2rem',
                                        display:
                                            currentPage === 4
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    <ReCAPTCHA
                                        sitekey={recaptchaSiteKey}
                                        onChange={setCaptchaValue}
                                        theme='dark'
                                        ref={recaptchaRef}
                                    />
                                </div>
                                <ReviewPage
                                    currentPage={currentPage}
                                    visitorDetails={visitorDetails}
                                    processing={processing}
                                />
                            </Form>
                        </div>
                    </>
                )}
                <ThankYouPage
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                {[2, 3, 4].includes(currentPage) && (
                    <>
                        <Button
                            id='next-btn'
                            className={
                                'nextBtn ' + (currentPage === 2 && 'fetch-page')
                            }
                            onClick={(_) =>
                                currentPage === 2
                                    ? fetchDetails()
                                    : changePage(currentPage + 1)
                            }
                            disabled={
                                currentPage === 4 && !visitorDetails.captcha
                                    ? true
                                    : false
                            }
                        >
                            {!processing ? (
                                <>
                                    Next
                                    <ArrowRight size={32} />
                                </>
                            ) : (
                                <span className='loading'></span>
                            )}
                        </Button>
                    </>
                )}
            </div>
            <div>
                <img src={watermark} id='watermark' />
            </div>
        </>
    );
};

export default App;
