import { Button } from 'react-bootstrap';
import { ArrowRight } from 'phosphor-react';

import { PageProps } from './types';

import './LandingPage.scss';

interface LandingPageProps extends PageProps {
    changePage: (page: number) => void;
}

export default function LandingPage({
    currentPage,
    changePage,
}: LandingPageProps) {
    if (currentPage !== 1) return null;
    return (
        <div id='landing-page' className='text-center'>
            <h3>
                Welcome
                <br />
                to
                <br />
                ALUCOR
            </h3>
            <h5>
                Kindly fill this form so we can make your visit a smooth
                experience
            </h5>
            <Button id='start-btn' onClick={_ => changePage(currentPage + 1)}>
                START
                <ArrowRight size={32} />
            </Button>
        </div>
    );
}
