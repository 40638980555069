import { Button } from 'react-bootstrap';
import { ArrowRight } from 'phosphor-react';

import { PageProps, VisitorDetailsProps } from './types';
import ReviewDetailItem from '../components/ReviewDetailItem';

import './ReviewPage.scss';

interface ReviewPageProps extends PageProps {
    visitorDetails: VisitorDetailsProps;
    processing: boolean;
}

export default function ReviewPage({
    currentPage,
    visitorDetails,
    processing,
}: ReviewPageProps) {
    if (currentPage !== 5) return null;
    return (
        <>
            <div id='review-page' className='h-100'>
                <h3>Review your details</h3>
                <div id='details'>
                    {(
                        Object.entries(visitorDetails) as Array<
                            [keyof typeof visitorDetails, string]
                        >
                    ).map(
                        (entry) =>
                            entry[0] !== 'captcha' && (
                                <ReviewDetailItem
                                    entry={entry}
                                    key={entry[0]}
                                />
                            )
                    )}
                </div>
                <Button id='submit-btn' className={'mt-3'} type='submit'>
                    {!processing ? (
                        <>
                            SUBMIT
                            <ArrowRight size={32} />
                        </>
                    ) : (
                        <span className='loading'></span>
                    )}
                </Button>
            </div>
        </>
    );
}
