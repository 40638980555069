import { Form } from 'react-bootstrap';
//@ts-ignore
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import './FormPage.scss';

export default function FetchInfoPage({
    currentPage,
    fetchInput,
    country,
    setCountry,
    setFetchInput,
}: {
    currentPage: number;
    fetchInput: string | undefined;
    country: string;
    setFetchInput: React.Dispatch<React.SetStateAction<string>>;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
}) {
    // const buttonKeyboardFix: React.ChangeEventHandler<HTMLInputElement> = (
    //     _
    // ) => {
    //     const nextBtn = document.getElementsByClassName(
    //         'nextBtn'
    //     )[0] as HTMLElement;
    //     nextBtn.style.bottom = 'auto';
    // };

    // const onBlurHandle: React.ChangeEventHandler<HTMLInputElement> = (_) => {
    //     const nextBtn = document.getElementsByClassName(
    //         'nextBtn'
    //     )[0] as HTMLElement;
    //     nextBtn.style.bottom = '2rem';
    // };

    if (currentPage !== 2) return null;
    return (
        <div id='fetch-info-page' className='form-page'>
            <h3>Enter phone number</h3>
            <Form.Group controlId='fetch_phone_number'>
                {/* <Form.Label>Phone Number<span>*</span></Form.Label> */}
                <PhoneInput
                    international
                    defaultCountry={country}
                    placeholder='Enter phone number'
                    countryCallingCodeEditable={false}
                    value={fetchInput}
                    onCountryChange={(country: string) => setCountry(country)}
                    onChange={(number: string) => setFetchInput(number)}
                    // onFocus={buttonKeyboardFix}
                    // onBlur={onBlurHandle}
                />
            </Form.Group>
        </div>
    );
}
