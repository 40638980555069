import { CheckCircle } from 'phosphor-react';
import { Button } from 'react-bootstrap';

import { ThankYouPageProps } from './types';

import './ThankYouPage.scss';

export default function FormPage1({
    currentPage,
    setCurrentPage,
}: ThankYouPageProps) {
    if (currentPage !== 6) return null;
    return (
        <div id='thank-you-page' className='text-center'>
            <CheckCircle size={70} className='mb-4' />
            <h3>
                Form submitted successfully
            </h3>
            <Button
                id='home-btn'
                className='mt-4'
                onClick={() => setCurrentPage(1)}
            >
                Back To Home
            </Button>
        </div>
    );
}
