import { Form } from 'react-bootstrap';
// @ts-ignore
import PhoneInput from 'react-phone-number-input';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormPageProps } from './types';

import 'react-phone-number-input/style.css';
import './FormPage.scss';

interface SecondFormPageProps extends FormPageProps {
    setPhoneNumber: (number: string) => void;
    setCaptchaValue: (value: string | null) => void;
    recaptchaRef: React.RefObject<ReCAPTCHA>;
}

export default function FormPage2({
    currentPage,
    changeForm,
    visitorDetails,
    setPhoneNumber,
    setCaptchaValue,
    recaptchaRef,
}: SecondFormPageProps) {
    const buttonKeyboardFix: React.ChangeEventHandler<HTMLInputElement> = _ => {
        const nextBtn = document.getElementsByClassName(
            'nextBtn'
        )[0] as HTMLElement;
        nextBtn.style.bottom = 'auto';
    };

    const onBlurHandle: React.ChangeEventHandler<HTMLInputElement> = _ => {
        const nextBtn = document.getElementsByClassName(
            'nextBtn'
        )[0] as HTMLElement;
        nextBtn.style.bottom = '2rem';
    };

    if (currentPage !== 4) return null;
    return (
        <div id='form-page-2' className='form-page'>
            <h3>Enter your details</h3>
            <Form.Group controlId='email'>
                <Form.Label>
                    Email<span>*</span>
                </Form.Label>
                <Form.Control
                    type='email'
                    placeholder='Enter email'
                    onChange={changeForm}
                    onFocus={buttonKeyboardFix}
                    onBlur={onBlurHandle}
                    value={visitorDetails.email}
                    pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                    required
                />
            </Form.Group>
            {/* <Form.Group controlId='phone_number'>
                <Form.Label>
                    Phone Number<span>*</span>
                </Form.Label>
                <PhoneInput
                    international
                    defaultCountry={'AE'}
                    placeholder='Enter phone number'
                    countryCallingCodeEditable={false}
                    value={visitorDetails.phone_number}
                    onChange={(number: string) => setPhoneNumber(number)}
                    onFocus={buttonKeyboardFix}
                    onBlur={onBlurHandle}
                />
            </Form.Group> */}
            <Form.Group controlId='meeting_details'>
                <Form.Label>
                    Purpose of Visit<span>*</span>
                </Form.Label>
                <Form.Control
                    as='select'
                    onChange={changeForm}
                    value={visitorDetails.meeting_details}
                    required
                >
                    <option value=''>Choose an Option</option>
                    <option>Business Meeting</option>
                    <option>Manpower Supply</option>
                    <option>Purchase Queries</option>
                    <option>Interview</option>
                    <option>Others</option>
                </Form.Control>
            </Form.Group>
        </div>
    );
}
