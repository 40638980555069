import { Form } from 'react-bootstrap';

import { FormPageProps } from './types';

import './FormPage.scss';

export default function FormPage1({
    currentPage,
    changeForm,
    visitorDetails,
}: FormPageProps) {
    const buttonKeyboardFix: React.ChangeEventHandler<HTMLInputElement> = _ => {
        const nextBtn = document.getElementsByClassName(
            'nextBtn'
        )[0] as HTMLElement;
        nextBtn.style.bottom = 'auto';
    };

    const onBlurHandle: React.ChangeEventHandler<HTMLInputElement> = _ => {
        const nextBtn = document.getElementsByClassName(
            'nextBtn'
        )[0] as HTMLElement;
        nextBtn.style.bottom = '2rem';
    };

    if (currentPage !== 3) return null;
    return (
        <div id='form-page-1' className='form-page'>
            <h3>Enter your details</h3>
            <Form.Group controlId='name'>
                <Form.Label>
                    Name<span>*</span>
                </Form.Label>
                <Form.Control
                    type='text'
                    placeholder='Enter name'
                    onChange={changeForm}
                    onFocus={buttonKeyboardFix}
                    onBlur={onBlurHandle}
                    value={visitorDetails.name}
                    required
                />
            </Form.Group>
            <Form.Group controlId='designation'>
                <Form.Label>
                    Designation<span>*</span>
                </Form.Label>
                <Form.Control
                    type='text'
                    placeholder='Enter designation'
                    onChange={changeForm}
                    onFocus={buttonKeyboardFix}
                    onBlur={onBlurHandle}
                    value={visitorDetails.designation}
                    required
                />
            </Form.Group>
            <Form.Group controlId='company_name'>
                <Form.Label>
                    Your Company Name<span>*</span>
                </Form.Label>
                <Form.Control
                    type='text'
                    placeholder='Enter your company name'
                    onChange={changeForm}
                    onFocus={buttonKeyboardFix}
                    onBlur={onBlurHandle}
                    value={visitorDetails.company_name}
                    required
                />
            </Form.Group>
        </div>
    );
}
