const LABELS = {
    name: 'Name',
    designation: 'Designation',
    company_name: 'Your Company Name',
    email: 'Email',
    phone_number: 'Phone Number',
    meeting_details: 'Purpose of Visit',
    captcha: ''
};

type ReviewDetailItemProps = {
    entry: [keyof typeof LABELS, string];
};

export default function ReviewDetailItem({
    entry,
}: ReviewDetailItemProps): JSX.Element {
    return (
        <div className=''>
            <h5>{LABELS[entry[0]]}</h5>
            <p>{entry[1]}</p>
        </div>
    );
}
