let apiURL: string;
let recaptchaSiteKey: string;
let recaptchaSecretKey: string;

if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'local'
) {
    apiURL = process.env.REACT_APP_API_URL_LOCAL as string;
    recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_LOCAL as string;
    recaptchaSecretKey = process.env
        .REACT_APP_RECAPTCHA_SECRET_KEY_LOCAL as string;
} else if (process.env.REACT_APP_ENV === 'staging') {
    apiURL = process.env.REACT_APP_API_URL_STAGING as string;
    recaptchaSiteKey = process.env
        .REACT_APP_RECAPTCHA_SITE_KEY_STAGING as string;
    recaptchaSecretKey = process.env
        .REACT_APP_RECAPTCHA_SECRET_KEY_STAGING as string;
} else if (process.env.REACT_APP_ENV === 'prod') {
    apiURL = process.env.REACT_APP_API_URL_PROD as string;
    recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY_PROD as string;
    recaptchaSecretKey = process.env
        .REACT_APP_RECAPTCHA_SECRET_KEY_PROD as string;
}

export { apiURL, recaptchaSiteKey, recaptchaSecretKey };
