import { ArrowLeft } from 'phosphor-react';
import { Button } from 'react-bootstrap';
import ProgressBar from '@ramonak/react-progress-bar';

import './Header.scss';

type HeaderProps = {
    currentPage: number;
    changePage: (page: number) => void;
};

export default function Header({
    currentPage,
    changePage,
}: HeaderProps): JSX.Element {
    return (
        <div id='header'>
            <Button id='back-btn' onClick={() => changePage(currentPage - 1)}>
                <ArrowLeft size={30} />
            </Button>
            {[2, 3, 4].includes(currentPage) && (
                <div id='progress' className='text-right'>
                    <div>
                        <ProgressBar
                            completed={((currentPage - 1) / 3) * 100}
                            bgColor='#296EB4'
                            height='5px'
                            borderRadius='5px'
                            isLabelVisible={false}
                            transitionTimingFunction='ease-out'
                            transitionDuration='300ms'
                        />
                        <span>{`Step ${currentPage - 1} of 3`}</span>
                    </div>
                </div>
            )}
        </div>
    );
}
